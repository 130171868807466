import { applyMiddleware, compose, createStore, Store } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { PrefecturesState } from './ducks/prefectures/types';
import { RolesState } from './ducks/roles/types';
import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSagas';
import { SchoolsState } from './ducks/schools/types';
import { UsersState } from './ducks/users/types';

export interface ApplicationState {
  users: UsersState;
  roles: RolesState;
  prefectures: PrefecturesState;
  schools: SchoolsState;
}

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, logger];

const store: Store<ApplicationState> = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export default store;
