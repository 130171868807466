import { action } from 'typesafe-actions';

import { Prefecture, PrefecturesTypes } from './types';

export const loadPrefecturesRequest = () =>
  action(PrefecturesTypes.LOAD_PREFECTURES_REQUEST);

export const loadPrefecturesSuccess = (data: Prefecture[]) =>
  action(PrefecturesTypes.LOAD_PREFECTURES_SUCCESS, { data });

export const loadPrefecturesRequestFilters = (params: FormData, page = 1) =>
  action(PrefecturesTypes.LOAD_PREFECTURES_REQUEST_FILTERS, {
    params: params,
    page: page,
  });

export const loadPrefecturesSuccessFilters = (
  data: Prefecture[],
  totalPage: number,
  currentPage: number
) =>
  action(PrefecturesTypes.LOAD_PREFECTURES_SUCCESS_FILTERS, {
    data,
    totalPage,
    currentPage,
  });

export const loadPrefecturesFailure = () =>
  action(PrefecturesTypes.LOAD_PREFECTURES_FAILURE);

// export const deleleUser = (id: number) =>
//   action(SchoolsTypes.DELETE_USER, { id: id });

// export const deleleSuccess = () => action(SchoolsTypes.DELETE_SUCCESS);

// export const registerUserRequest = (params: FormData) =>
//   action(SchoolsTypes.REGISTER_REQUEST, { params: params });

// export const registerUserSuccess = () => action(SchoolsTypes.REGISTER_SUCCESS);
