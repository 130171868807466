import { call, put } from 'redux-saga/effects';

import api from '../../../components/Api';
import { deleleSuccess, loadFailure, loadSuccess, registerUserSuccess } from './actions';
import { ResponseGenerator } from './types';

export function* load(action: any) {
  try {
    const response: ResponseGenerator = yield call(
      api.post,
      `/admin/users/filters?page=${action.payload.page}`,
      action.payload.params
    );

    yield put(
      loadSuccess(
        response.data.users.data,
        response.data.users.total,
        response.data.users.current_page
      )
    );
  } catch (error) {
    yield put(loadFailure(error));
  }
}

export function* userDelete(action: any) {
  try {
    yield call(api.delete, `/admin/users/${action.payload.id}`);
    yield put(deleleSuccess());
  } catch (error) {
    yield put(loadFailure(error));
  }
}

export function* register(action: any) {
  try {
    const response: ResponseGenerator = yield call(
      api.post,
      `/admin/users`,
      action.payload.params
    );
    if (response.status === 201) {
      yield put(registerUserSuccess());
    } else {
      yield put(loadFailure(response.data));
    }
  } catch (error) {
    yield put(loadFailure(error));
  }
}
