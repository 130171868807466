import { Reducer } from 'redux';

import { PrefecturesState, PrefecturesTypes } from './types';

const INITIAL_STATE: PrefecturesState = {
  data: [],
  error: false,
  loading: false,
  totalPage: 0,
  currentPage: 0,
  success: false,
  loaderDelete: false,
  // form: {
  //   name: "",
  //   email: "",
  //   role_id: 0,
  //   phone: "",
  //   city: "",
  //   uf: "",
  //   document: "",
  //   zip_code: "",
  //   address: "",
  //   number: 0,
  // },
};

const reducer: Reducer<PrefecturesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PrefecturesTypes.LOAD_PREFECTURES_REQUEST:
      return { ...state, loading: true, success: false };
    case PrefecturesTypes.LOAD_PREFECTURES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };

    case PrefecturesTypes.LOAD_PREFECTURES_REQUEST_FILTERS:
      return { ...state, loading: true, success: false };
    case PrefecturesTypes.LOAD_PREFECTURES_SUCCESS_FILTERS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
        totalPage: action.payload.totalPage,
        currentPage: action.payload.currentPage,
        success: false,
      };
    case PrefecturesTypes.LOAD_PREFECTURES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      };
    // case UsersTypes.DELETE_USER:
    //   return {
    //     ...state,
    //     loaderDelete: true,
    //     success: false,
    //   };
    // case UsersTypes.DELETE_SUCCESS:
    //   return {
    //     ...state,
    //     loaderDelete: false,
    //     success: true,
    //   };
    // case UsersTypes.REGISTER_REQUEST:
    //   return { ...state, loading: true, error: false };
    // case UsersTypes.REGISTER_SUCCESS:
    //   return { ...state, loading: false, error: false, success: true };
    default:
      return state;
  }
};

export default reducer;
