import { Reducer } from 'redux';

import { RolesState, RolesType } from './types';

const INITIAL_STATE: RolesState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<RolesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RolesType.LOAD_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RolesType.LOAD_ROLES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RolesType.LOAD_ROLES_FAILURE:
      return { ...state, data: [], loading: false, error: true };
    default:
      return state;
  }
};

export default reducer;
