import { action } from 'typesafe-actions';

import { User, UsersTypes } from './types';

export const loadRequest = (params: FormData, page = 1) =>
  action(UsersTypes.LOAD_REQUEST, { params: params, page: page });

export const loadSuccess = (
  data: User[],
  totalPage: number,
  currentPage: number
) => action(UsersTypes.LOAD_SUCCESS, { data, totalPage, currentPage });

export const loadFailure = (error: any) =>
  action(UsersTypes.LOAD_FAILURE, { error });

export const deleleUser = (id: number) =>
  action(UsersTypes.DELETE_USER, { id: id });

export const deleleSuccess = () => action(UsersTypes.DELETE_SUCCESS);

export const registerUserRequest = (params: FormData) =>
  action(UsersTypes.REGISTER_REQUEST, { params: params });

export const registerUserSuccess = () => action(UsersTypes.REGISTER_SUCCESS);
