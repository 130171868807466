import { call, put } from 'redux-saga/effects';

import api from '../../../components/Api';
import {
    loadPrefecturesFailure, loadPrefecturesSuccess, loadPrefecturesSuccessFilters
} from './actions';
import { ResponseGenerator } from './types';

export function* load() {
  try {
    const response: ResponseGenerator = yield call(
      api.get,
      `/admin/prefectures`
    );

    yield put(loadPrefecturesSuccess(response.data.prefectures));
  } catch (error) {
    yield put(loadPrefecturesFailure());
  }
}

export function* loadFilters(action: any) {
  try {
    const response: ResponseGenerator = yield call(
      api.post,
      `/admin/prefectures/filters?page=${action.payload.page}`,
      action.payload.params
    );

    yield put(
      loadPrefecturesSuccessFilters(
        response.data.users.data,
        response.data.users.total,
        response.data.users.current_page
      )
    );
  } catch (error) {
    yield put(loadPrefecturesFailure());
  }
}

// export function* userDelete(action: any) {
//   try {
//     yield call(api.delete, `/admin/users/${action.payload.id}`);
//     yield put(deleleSuccess());
//   } catch (error) {
//     yield put(loadFailure());
//   }
// }

// export function* register(action: any) {
//   try {
//     yield call(api.post, `/admin/users`, action.payload.params);
//     yield put(registerUserSuccess());
//   } catch (error) {
//     yield put(loadFailure());
//   }
// }
