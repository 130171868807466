export enum SchoolsTypes {
  LOAD_SCHOOLS_REQUEST = "@schools/LOAD_SCHOOLS_REQUEST",
  LOAD_SCHOOLS_SUCCESS = "@schools/LOAD_SCHOOLS_SUCCESS",
  LOAD_SCHOOLS_REQUEST_FILTERS = "@schools/LOAD_SCHOOLS_REQUEST_FILTERS",
  LOAD_SCHOOLS_SUCCESS_FILTERS = "@schools/LOAD_SCHOOLS_SUCCESS_FILTERS",
  LOAD_SCHOOLS_FAILURE = "@schools/LOAD_SCHOOLS_FAILURE",
  // DELETE_USER = "@users/DELETE_USER",
  // DELETE_SUCCESS = "@users/DELETE_SUCCESS",
  // REGISTER_REQUEST = "@users/REGISTER_REQUEST",
  // REGISTER_SUCCESS = "@users/REGISTER_SUCCESS",
}

export interface School {
  id: number;
  name: string;
  email: string;
  document: string;
  responsible: string;
  phone: string;
  city: string;
  uf: string;
  prefectureName: string;
  categoriesName: string;
  created_at: string;
}

// export interface UserForm {
//   name: string;
//   email: string;
//   role_id: number;
//   phone: string;
//   city: string;
//   uf: string;
//   password?: string;
//   password_confirmation?: string;
//   document: string;
//   zip_code: string;
//   address: string;
//   number: number;
//   complement?: string;
//   prefecture_id?: number;
//   school_id?: number;
// }

export interface SchoolsState {
  readonly data: School[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly totalPage: number;
  readonly currentPage: number;
  readonly success: boolean;
  readonly loaderDelete: boolean;
  // readonly form: UserForm;
}

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
