import { all, takeLatest } from 'redux-saga/effects';

import { load as loadPrefecture } from './prefectures/sagas';
import { PrefecturesTypes } from './prefectures/types';
import { load as LoadRoles } from './roles/sagas';
import { RolesType } from './roles/types';
import { load as LoadSchools } from './schools/sagas';
import { SchoolsTypes } from './schools/types';
import { load, register, userDelete } from './users/sagas';
import { UsersTypes } from './users/types';

export default function* rootSaga(): any {
  return yield all([
    takeLatest(UsersTypes.LOAD_REQUEST, load),
    takeLatest(UsersTypes.DELETE_USER, userDelete),
    takeLatest(UsersTypes.REGISTER_REQUEST, register),
    takeLatest(RolesType.LOAD_ROLES_REQUEST, LoadRoles),
    takeLatest(SchoolsTypes.LOAD_SCHOOLS_REQUEST, LoadSchools),
    takeLatest(PrefecturesTypes.LOAD_PREFECTURES_REQUEST, loadPrefecture),
  ]);
}
