export enum RolesType {
  LOAD_ROLES_REQUEST = "@roles/LOAD_ROLES_REQUEST",
  LOAD_ROLES_SUCCESS = "@roles/LOAD_ROLES_SUCCESS",
  LOAD_ROLES_FAILURE = "@roles/LOAD_ROLES_FAILURE",
}

export interface Role {
  id: number;
  name: string;
  status: string;
  created: string;
  updated: string;
}

export interface RolesState {
  readonly data: Role[];
  readonly loading: boolean;
  readonly error: boolean;
}

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
