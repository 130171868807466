import { combineReducers } from 'redux';

import prefectures from './prefectures';
import roles from './roles';
import schools from './schools';
import users from './users';

export default combineReducers({
  users,
  roles,
  schools,
  prefectures,
});
