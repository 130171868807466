import { call, put } from 'redux-saga/effects';

import api from '../../../components/Api';
import { loadRolesFailure, loadRolesSuccess } from './actions';
import { ResponseGenerator } from './types';

export function* load() {
  try {
    const response: ResponseGenerator = yield call(api.get, `/admin/roles`);
    yield put(loadRolesSuccess(response.data.roles));
  } catch (error) {
    yield put(loadRolesFailure());
  }
}
