import axios from "axios";
import { getToken, logout } from "./Auth";

export const urlApi = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: urlApi,
  //baseURL: baseURLProd,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  function (error) {
    if (error.response === undefined) {
      window.location.reload();
      // logout();
      // window.location.href = "/#/login";
    } else if (error.response.status === 401) {
      //window.location.reload();
      logout();
      window.location.href = "/#/login";
    }
    return Promise.reject(error.response);
  }
);

export default api;
