export enum UsersTypes {
  LOAD_REQUEST = "@users/LOAD_REQUEST",
  LOAD_SUCCESS = "@users/LOAD_SUCCESS",
  LOAD_FAILURE = "@users/LOAD_FAILURE",
  DELETE_USER = "@users/DELETE_USER",
  DELETE_SUCCESS = "@users/DELETE_SUCCESS",
  REGISTER_REQUEST = "@users/REGISTER_REQUEST",
  REGISTER_SUCCESS = "@users/REGISTER_SUCCESS",
}

export interface User {
  userId: number;
  name: string;
  email: string;
  role_id: number;
  phone: string;
  city: string;
  uf: string;
  roleName: string;
}

export interface UserForm {
  name: string;
  email: string;
  role_id: number;
  phone: string;
  city: string;
  uf: string;
  password?: string;
  password_confirmation?: string;
  document: string;
  zip_code: string;
  address: string;
  number: number;
  complement?: string;
  prefecture_id?: number;
  school_id?: number;
}

export interface UsersState {
  readonly data: User[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorMessage: string;
  readonly totalPage: number;
  readonly currentPage: number;
  readonly success: boolean;
  readonly loaderDelete: boolean;
  readonly form: UserForm;
}

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
