export enum PrefecturesTypes {
  LOAD_PREFECTURES_REQUEST = "@prefectures/LOAD_PREFECTURES_REQUEST",
  LOAD_PREFECTURES_SUCCESS = "@prefectures/LOAD_PREFECTURES_SUCCESS",
  LOAD_PREFECTURES_REQUEST_FILTERS = "@prefectures/LOAD_PREFECTURES_REQUEST_FILTERS",
  LOAD_PREFECTURES_SUCCESS_FILTERS = "@prefectures/LOAD_PREFECTURES_SUCCESS_FILTERS",
  LOAD_PREFECTURES_FAILURE = "@prefectures/LOAD_PREFECTURES_FAILURE",
  // DELETE_USER = "@users/DELETE_USER",
  // DELETE_SUCCESS = "@users/DELETE_SUCCESS",
  // REGISTER_REQUEST = "@users/REGISTER_REQUEST",
  // REGISTER_SUCCESS = "@users/REGISTER_SUCCESS",
}

export interface Prefecture {
  id: number;
  name: string;
  email: string;
  document: string;
  responsible: string;
  phone: string;
  city: string;
  uf: string;
  image: string;
  created_at: string;
}

// export interface UserForm {
//   name: string;
//   email: string;
//   role_id: number;
//   phone: string;
//   city: string;
//   uf: string;
//   password?: string;
//   password_confirmation?: string;
//   document: string;
//   zip_code: string;
//   address: string;
//   number: number;
//   complement?: string;
//   prefecture_id?: number;
//   school_id?: number;
// }

export interface PrefecturesState {
  readonly data: Prefecture[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly totalPage: number;
  readonly currentPage: number;
  readonly success: boolean;
  readonly loaderDelete: boolean;
  // readonly form: UserForm;
}

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
