import { action } from 'typesafe-actions';

import { School, SchoolsTypes } from './types';

export const loadSchoolsRequest = () =>
  action(SchoolsTypes.LOAD_SCHOOLS_REQUEST);

export const loadSchoolsSuccess = (data: School[]) =>
  action(SchoolsTypes.LOAD_SCHOOLS_SUCCESS, { data });

export const loadSchoolsRequestFilters = (params: FormData, page = 1) =>
  action(SchoolsTypes.LOAD_SCHOOLS_REQUEST_FILTERS, {
    params: params,
    page: page,
  });

export const loadSchoolsSuccessFilters = (
  data: School[],
  totalPage: number,
  currentPage: number
) =>
  action(SchoolsTypes.LOAD_SCHOOLS_SUCCESS_FILTERS, {
    data,
    totalPage,
    currentPage,
  });

export const loadSchoolsFailure = () =>
  action(SchoolsTypes.LOAD_SCHOOLS_FAILURE);

// export const deleleUser = (id: number) =>
//   action(SchoolsTypes.DELETE_USER, { id: id });

// export const deleleSuccess = () => action(SchoolsTypes.DELETE_SUCCESS);

// export const registerUserRequest = (params: FormData) =>
//   action(SchoolsTypes.REGISTER_REQUEST, { params: params });

// export const registerUserSuccess = () => action(SchoolsTypes.REGISTER_SUCCESS);
